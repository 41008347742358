/* src/components/SettingsModal/SettingsModal.css */
.SettingsModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-content {
    width: 500px;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.SettingsModal h2 {
    margin: 0;
    margin-bottom: 16px;
}

.SettingsModal label {
    display: block;
    margin-bottom: 8px;
}

.SettingsModal input {
    display: block;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 16px;
}

.SettingsModal .buttons {
    display: flex;
    justify-content: space-between;
}

.SettingsModal .cancel,
.SettingsModal .submit {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.SettingsModal .cancel {
    background-color: #cccccc;
    color: #333333;
}

.SettingsModal .submit {
    background-color: #007bff;
    color: #ffffff;
}

.SettingsModal .cancel:focus,
.SettingsModal .submit:focus {
    outline: none;
}