html,
body,
#root,
.LiveApp {
  margin: 0;
  height: 97vh;
}

.LiveApp {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.live-chart-container {
  flex: 1;
}