/* src/pages/Analytics.css */
.App {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
}

.chart-container {
  grid-column: 1 / 2;
  grid-row: 1;
  position: relative;
}

.stage-list-container {
  grid-column: 2 / 3;
  grid-row: 1;
  border-left: 1px solid #ccc;
  background-color: burlywood;
}