/* src/pages/Analytics.css */
.App {
  height: 95vh;
}

/* .chart-container {
  grid-column: 1 / 2;
  grid-row: 1;
  position: relative;
}

.stage-list-container {
  grid-column: 2 / 3;
  grid-row: 1;
  border-left: 1px solid #ccc;
  background-color: burlywood;
} */