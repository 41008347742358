/* src/components/StageList/StageList.css */

.StageList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stage-list-controls {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.stage-list-controls button {
  background-color: #cccccc;
  border: none;
  color: white;
  cursor: pointer;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  transition-duration: 0.4s;
  border-radius: 4px;
  outline: none;
}

.stage-list-controls button:hover {
  background-color: #007bff;
}

.stage-list-controls button.active {
  background-color: #007bff;
}

.stage-list-controls button.active:hover {
  background-color: #0069d9;
}

.stage-list-items {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 100vh;
}

.stage-list-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stage-list-items li {
  padding: 8px;
  cursor: pointer;
}

.stage-list-items li.selected {
  background-color: #eee;
}
