.FloatingButton {
    position: fixed;
    top: 16px;
    left: 16px;
    width: 48px;
    height: 48px;
    background-color: #b33636;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 100;
  }
  
  .FloatingButton:focus {
    outline: none;
  }
  